import React, { useEffect, useRef, useState } from "react";
import Timer from "react-compound-timer";
import Webcam from "react-webcam";
import { detectWebcam } from "../../Utils";
import CameraUnable from '../../assets/images/camera-unable.svg';

const mediaTypesList = [
  "video/webm",
  "video/mp4",
  "video/webm;codecs=vp8",
  "video/webm;codecs=daala",
  "video/webm;codecs=h264",
  "video/mpeg",
];

const isMediaTypeSupported = (types) => {
  for (const type of types) {
    if (MediaRecorder.isTypeSupported(type)) {
      return type
    }
  }
}


const SelfieVideoDesktop = ({ dispatch, handleOnClickNext }) => {
  const supportedMimeType = isMediaTypeSupported(mediaTypesList);
  const webcamRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const [capturing, setCapturing] = useState(false);
  const [finish, setFinish] = useState(false);
  const [, setRecordedChunks] = useState([]);
  const [webCamAvailable, setWebCamAvailable] = useState(false);
  const [cameraLoaded, setCameraLoaded] = useState(false);
  const [loading, setLoading] = useState(true);
  const [selfieVideo, setSelfieVideo] = useState([]);

  const videoConstraints = {
    width: 720,
    height: 720,
    facingMode: "user",
  };

  useEffect(() => {
    detectWebcam((hasWebcam) => {
      setWebCamAvailable(hasWebcam);
    });
  }, []);

  useEffect(() => {
    dispatch({
      type: "selfieVideo",
      value: [selfieVideo],
    });
  }, [selfieVideo, dispatch]);

  const handleDataAvailable = React.useCallback(
    ({ data }) => {
      if (data.size > 0) {
        setRecordedChunks((prev) => {
          prev.concat(data);
          let file = new File([data], "video.mp4");
          setSelfieVideo(file);
        });
      }
    },
    [setRecordedChunks]
  );

  const handleStopCaptureClick = React.useCallback(() => {
    mediaRecorderRef.current.stop();
    setCapturing(false);
    setFinish(true);

    setTimeout(() => {
      setLoading(false);
    }, 3500);
  }, [mediaRecorderRef, setCapturing, setFinish]);

  const handleStartCaptureClick = React.useCallback(
    (e) => {
      e.preventDefault();
      setTimeout(() => {
        handleStopCaptureClick();
      }, 5000);
      setCapturing(true);
      mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
        mimeType: supportedMimeType,
      });
      mediaRecorderRef.current.addEventListener(
        "dataavailable",
        handleDataAvailable
      );
      mediaRecorderRef.current.start();
    },
    [webcamRef, setCapturing, mediaRecorderRef, handleDataAvailable, handleStopCaptureClick, supportedMimeType]
  );

  const handleRetry = React.useCallback(
    (e) => {
      e.preventDefault();
      setFinish(false)
      setRecordedChunks([])
    }, [setRecordedChunks, setFinish]
  );


  return (
    <>
      {webCamAvailable ? (
        <>
          {!finish ? (
            <div className="w-full flex flex-col align-center flex-grow fade-in">
              <label
                className="block text-gray-700 text-base font-bold mb-2 md:text-md xl:text-lg">
                Generaremos la grabación de tu rostro
                <br />
                Realiza dos circulos con la cabeza para validar tu identidad
              </label>
              <label
                className="block text-gray-600 text-sm font-bold mb-2 text-base">
                Presione el botón azul para iniciar la grabración.
              </label>
              <div
                className="webcam-container w-full h-full"
                style={{ position: "relative" }}>
                <div className="flex flex-col w-full h-full items-center justify-evenly">
                  <div className="flex justify-center w-full h-full video-container xl:w-3/4 xl:h-3/4">
                    <Webcam
                      id="selfie-camera"
                      className="self-center rounded-full relative object-cover"
                      videoConstraints={videoConstraints}
                      audio={false}
                      width="70%"
                      height="70%"
                      ref={webcamRef}
                      mirrored={true}
                      onUserMedia={(e) => e.active ? setCameraLoaded(true) : setCameraLoaded(false)}
                      size={500} />
                  </div>
                  {cameraLoaded ? (
                    <>
                      {capturing ? (
                        <p className="fn-color mt-5 mb-5 text-lg text-gray-900">
                          El video finalizará automaticamente en {" "}
                          <Timer initialTime={5000} direction="backward">
                            {() => (
                              <React.Fragment>
                                <span className="text-base text-fna font-bold"><Timer.Seconds /></span> segundos.
                              </React.Fragment>
                            )}
                          </Timer>
                        </p>
                      ) :
                        <button
                          className="fna-color py-2 px-4 mt-5 mb-5 rounded focus:outline-none focus:shadow-outline flex gap-2 items-center cursor-pointer"
                          onClick={handleStartCaptureClick}>
                          <svg className="h-4 w-4 fna-color" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">  <polygon points="23 7 16 12 23 17 23 7" /> <rect x="1" y="5" width="15" height="14" rx="2" ry="2" /></svg>
                          <span>
                            Iniciar grabración
                          </span>
                        </button>
                      }
                    </>
                  ) :
                    <div className="flex flex-col self-center items-center">
                      <svg className="animate-spin object-contain w-1/3 h-1/3 lg:w-1/4 self-center text-fna absolute top-0 mt-8 md:12 lg:mt-20" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      <p className="text-gray-900">
                        Cargando cámara...
                      </p>
                    </div>
                  }
                </div>


              </div>
            </div>
          ) : (
            <div className="w-full flex flex-col align-center flex-grow fade-in lg:pt-8">
              <h1 className="block text-gray-700 text-base font-bold mb-2 mt-4 text-base md:text-lg xl:text-xl lg:mt-8 lg:mb-4">
                Proceso de video
              </h1>
              <div className="h-full flex flex-col items-center justify-center text-base md:text-lg lg:text-xl">
                <h1 className="text-gray-900">El video se ha capturado con éxito.</h1>
                <svg className="text-fna h-48 w-full object-cover md:w-48 mt-6" version="1.1" x="0px" y="0px" viewBox="0 0 1000 1000">
                  <g>
                    <g transform="translate(0.000000,460.000000) scale(0.100000,-0.100000)">
                      <path fill="currentColor" d="M155.5,3946.4l-55.5-55.5V249.7v-3641.2l55.5-55.5l55.5-55.5h2538.5c1395.6,0,2538.5,5.7,2538.5,15.3c0,7.7-17.2,74.7-38.3,151.2c-65.1,245-82.3,432.6-70.8,758.1c7.7,245,21.1,352.3,59.4,507.3c191.4,760,683.4,1370.7,1386,1724.9c323.5,162.7,654.7,245,1052.9,264.2L7949-70v84.2v82.3l647.1-3.8l645.2-5.7l9.6-310.1l9.6-312l158.9-124.4l157-124.4v2337.5v2337.5l-55.5,55.5l-55.5,55.5H4838.1H211L155.5,3946.4z M1727.2,2939.4v-737h-650.9H425.4v737v737h650.9h650.9V2939.4z M9250.7,2939.4v-737h-650.9H7949v737v737h650.9h650.9V2939.4z M1727.2,1149.5V422h-650.9H425.4v727.5v727.5h650.9h650.9V1149.5z M9250.7,1149.5V422h-650.9H7949v727.5v727.5h650.9h650.9V1149.5z M5173.1,958c1007-639.4,1008.9-641.3,957.2-763.8c-21-51.7-453.7-336.9-1740.2-1144.8c-181.9-114.9-247-126.3-317.8-53.6l-47.9,45.9v1208v1208l47.9,45.9C4164.2,1597.4,4170,1595.5,5173.1,958z M1727.2-640.5v-737h-650.9H425.4v737v737h650.9h650.9V-640.5z M1727.2-2440v-737h-650.9H425.4v737v737h650.9h650.9V-2440z" />
                      <path fill="currentColor" d="M7451.2-586.9c-151.3-24.9-426.9-111-564.7-176.1C6048-1155.4,5557.9-2036.1,5670.8-2943.5c137.8-1093.1,1119.9-1918.2,2211.1-1855c541.8,30.6,985.9,225.9,1374.5,605c631.7,614.5,815.5,1521.9,474.8,2339.4c-260.4,627.9-874.9,1121.8-1552.6,1250.1C8033.2-575.4,7577.6-565.8,7451.2-586.9z M8136.6-929.5c999.3-206.8,1623.4-1190.8,1382.2-2186.2c-151.2-624.1-679.6-1154.4-1301.8-1305.6C7227.2-4660.7,6268.1-4071,6021.2-3066c-47.9,195.3-44,580,9.6,796.4c168.5,675.8,700.7,1194.6,1374.5,1340.1C7585.2-891.3,7952.8-891.3,8136.6-929.5z" />
                      <path fill="currentColor" d="M8651.5-1764.2c-24.9-24.9-235.5-315.9-465.2-647.1c-227.8-331.2-430.7-622.2-448-645.1c-30.6-40.2-34.5-38.3-356.1,227.8c-178,145.5-342.7,275.7-367.6,285.2c-61.3,24.9-132.1,1.9-176.1-53.6c-49.8-65.1-47.9-145.5,5.7-204.8c84.2-93.8,821.3-681.5,871.1-694.9c28.7-5.7,68.9,0,95.7,15.3c47.9,26.8,1062.5,1456.9,1097,1548.7C8969.3-1781.4,8772.1-1653.2,8651.5-1764.2z" />
                    </g>
                  </g>
                </svg>
              </div>
              <div className="flex m-2 pb-4 self-center">
                <button
                  className="text-base hover:scale-110 flex justify-center px-4 py-2 cursor-pointer 
                      duration-200 ease-in-out transition"
                  onClick={handleRetry}
                >
                  Reintentar
                </button>
                <button
                  className="flex justify-center text-base ml-2 rounded focus:outline-none px-4 py-2 rounded cursor-pointer 
                      fna-color disabled:bg-slate-200 disabled:cursor-default"
                  onClick={handleOnClickNext}
                  disabled={loading}
                >
                  {loading ? <> <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg> Procesando video </> : "Cargar video"}
                </button>
              </div>
            </div>
          )}
        </>
      ) : (
        <div className="h-full flex flex-col items-center justify-center text-gray-900">
          <p className="text-2xl">No hay camara disponible</p>
          <div>
            <img className="h-48 w-full object-cover md:w-48" src={CameraUnable} alt="No hay camara disponible" />
          </div>
          <p className="text-xl p-8">
            No hemos detectado una camara, o no ha dado los permisos necesarios, por favor verifique.
          </p>
        </div>
      )}
    </>
  );
};

export default SelfieVideoDesktop;