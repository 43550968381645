import React from "react";
import WebcamStreamCapture from "../WebStreamCapture";
import Liveness from '../../assets/images/liveness.gif';

const SelfieVideo = ({ dispatch, handleOnClickNext }) => {
  return (
    <div className="w-full flex flex-col align-center justify-evenly flex-grow fade-in">
      <h2 className="block text-gray-700 text-sm font-bold mb-5">
        Video de tu rostro haciendo 2 círculos con tu cabeza.
      </h2>
      <h3 className="block text-gray-600 text-sm font-bold mb-0">
        Después de presionar el botón azul se abrirá su cámara, por favor presione el botón para iniciar la grabración.
      </h3>
      <img src={Liveness} alt="Liveness" className="object-contain w-full h-full lg:w-1/3 self-center" />
      <WebcamStreamCapture
        handleOnClickNext={handleOnClickNext}
        dispatch={dispatch}
      />
    </div>
  );
};

export default SelfieVideo;
