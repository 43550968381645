import React, { Fragment, useEffect, useState } from "react";
import CheckMark from '../../assets/images/check-mark.png';
import CancelImg from '../../assets/images/cancel.png';
import { sendMessage } from "./../../Utils";

const findTerm = (str, term) => {
  if (str.includes(term)) {
    return str;
  }
};

function ErrorList(props) {
  const errores = props.errores;
  const listItems = errores.map((err) => {
    switch (err.code) {
      case findTerm(err.code, 'identicalImages'):
        err.code = "La imagen que adjuntas del documento de identificación en ambas caras es igual, corrige e intentalo nuevamente"
        break;
      case findTerm(err.code, 'similarImages'):
        err.code = "La imagen que adjuntas del documento de identificación en ambas caras es igual, corrige e intentalo nuevamente"
        break;
      case findTerm(err.code, 'badText'):
        err.code = "Mal texto en el documento"
        break;
      case findTerm(err.code, 'blurryText'):
        err.code = "El texto del documento que adjuntas es borroso, corrige e  intentalo nuevamente"
        break;
      case findTerm(err.code, 'smallImageSize'):
        err.code = "La resolución de la imagen que adjuntas es muy pequeña, corrige e intentalo nuevamente"
        break;
      case findTerm(err.code, 'voiceVideo.noFace'):
      case findTerm(err.code, 'selfieVideo.noFace'):
        err.code = "No podemos identificar ningún rostro en el video, vuelve a intentar la grabación"
        break;
      case findTerm(err.code, 'documentPhoto.noFace'):
        err.code = "No podemos identificar ningún rostro en el documento, corrige e intentalo nuevamente"
        break;
      case findTerm(err.code, 'noDocument'):
        err.code = "La imagen que adjuntas no tiene documento para leer, verifica e intentalo nuevamente"
        break;
      case findTerm(err.code, 'missingFields'):
        err.code = "La imagen que adjuntas no tiene algunos campos requeridos para leer, verifica e intentalo nuevamente"
        break;
      case findTerm(err.code, 'internalError'):
        err.code = "Fallo interno del servidor"
        break;
      case findTerm(err.code, 'multipleFaces'):
        err.code = "Hemos identificado varios rostros en el video, vuelve a intentar la grabación"
        break;
      case findTerm(err.code, 'unexpectedData'):
        err.code = "Ocurrió un error en la lectura del documento, inicia nuevamente el proceso de validación";
        break;
      case findTerm(err.code, 'noText'):
        err.code = "El documento no tiene texto";
        break;
      case findTerm(err.code, 'grayscaleImage'):
        err.code = "El documento está en escala de grisis o es una copia";
        break;
      case findTerm(err.code, 'screenPhoto'):
        err.code = "El documento es una captura de pantalla";
        break;
      case findTerm(err.code, 'wrongFormat'):
        err.code = "La imagen que adjuntas no tiene formato válido en algunos campos, verifica e intentalo nuevamente";
        break;
      case findTerm(err.code, 'noMrz'):
        err.code = "El documento no tiene una zona leible por máquinas MRZ";
        break;
      case findTerm(err.code, 'badMrz'):
        err.code = "El documento tiene la zona leible por máquinas MRZ alterado";
        break;
      case findTerm(err.code, 'noPdf417'):
        err.code = "El documento no tiene un barcode PDF417";
        break;
      case findTerm(err.code, 'badPdf417'):
        err.code = "El documento tiene un barcode PDF417 alterado";
        break;
      case findTerm(err.code, 'typeMismatch'):
        err.code = "El documento analizado no coincide con el tipo de coumento seleccionado";
        break;
      case findTerm(err.code, 'countryMismatch'):
        err.code = "El tipo documentos no coinciden con el país seleccionado";
        break;
      case findTerm(err.code, 'conversionFailed'):
        err.code = "La conversión del vídeo ha fallado";
        break;
      default:
        err.code = `${err.code}`;
        break;
    }
    return (
      <li className="text-lg md:text-xl" key={err.documentError}>
        {err.documentError} - {err.code}
      </li>
    )
  });
  return <ul className="text-lg mt-2 md:text-xl">{listItems}</ul>;
}

const FinalStep = (props) => {
  const [isLoading, setIsloading] = useState();
  const [fetchResult, setFetchResult] = useState([]);
  const [hadError, setHadError] = useState(false);

  const refreshPage = (e) => {
    e.preventDefault();
    window.location.replace(
      window.location.pathname + window.location.search + window.location.hash
    );
    // window.location.assign(window.location.href)
  };

  useEffect(() => {
    setIsloading(true);
    var files = new FormData();

    files.append("document1", props.state.frontDocument[0], "front.jpg");
    if (props.state.documentType !== 'passport') {
      files.append("document2", props.state.backDocument[0], "back.jpg");
    }
    if (props.canVideo) {
      files.append("video", props.state.selfieVideo[0], "video.mp4");
    }

    if (props.emailAddress) {
      files.append("email_address", props.emailAddress);
    }

    if (props.emailAddress) {
      files.append("phone_number", props.phoneNumber);
    }

    files.append("user_id", props.userId);
    files.append("contract_id", props.contractId);
    files.append("file_type", props.state.documentType);
    if (props.country) {
      files.append("country", props.country);
    } else {
      files.append("country", props.state.country);
    }

    if (props.phoneNumberEnc) {
      files.append("phone_number_enc", props.phoneNumberEnc);
    }

    const URL_VALIDATION =
      "https://becomedigital-api.azurewebsites.net/api/v1/identity";

    setTimeout(() => {
      fetch(URL_VALIDATION, {
        method: "POST",
        headers: new Headers({
          Authorization: `Bearer ${props.token}`,
        }),
        body: files,
      }).then((response) => {
        response.json().then((data) => {
          var fetchData = data;
          setFetchResult(fetchData);
          sendMessage('fetchResult', { "data": fetchData })
          setIsloading(false)
        });
      }).catch((error) => {
        setHadError(true)
      });
    }, 1000);
  }, [props]);

  const handleFinishValidation = (e) => {
    e.preventDefault();
    sendMessage('userFinishedSdk', { "data": fetchResult })
  };

  return (
    <Fragment>
      {isLoading ?
        (
          <div className="w-full h-full flex flex-col align-center justify-evenly md:p-8">
            <div className="w-full h-full flex align-center justify-center">
              <svg className="animate-spin object-contain w-1/4 h-1/4 lg:w-1/2 lg:h-1/2 self-center text-fna" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
            </div>
            <p className="text-center w-full mt-4 text-lg md:text-xl text-gray-900">Estamos procesando sus datos...</p>
          </div>
        ) :
        (<div className="w-full flex flex-col align-center justify-evenly flex-grow fade-in">
          <h1 className="block text-gray-700 text-base font-bold mb-2 mt-4 text-base md:text-lg lg:text-xl lg:mt-8 lg:mb-4">Fin de la verificación</h1>
          {fetchResult.error && (
            fetchResult.message === "Hubo un problema con el envio de los archivos" ?
              (
                <>
                  <img src={CancelImg} alt="Cancel" className="w-24 h-24 self-center" />

                  <ErrorList errores={fetchResult.error} />

                  <p className="text-center mt-2 text-lg md:text-xl">Hubo en error con el envío de sus documentos, por favor reintente la validación.</p>

                  <input
                    className="fna-color font-bold py-2 px-4 mt-5 mb-5 rounded focus:outline-none focus:shadow-outline"
                    type="button"
                    value="Reintentar"
                    onClick={refreshPage}
                  />
                </>
              ) : (
                <>
                  <p className="text-lg md:text-xl">
                    Contacte al administrador del sistema e informe este código y mensaje de error:
                  </p>
                  <p className="text-lg md:text-xl">
                    {fetchResult.error}
                  </p>
                  <img src={CancelImg} alt="Cancel" className="w-24 h-24 self-center" />
                  <input
                    className="fna-color-secoundary py-2 px-4 mt-5 mb-5 rounded focus:outline-none focus:shadow-outline cursor-pointer"
                    type="button"
                    value="Finalizar"
                    onClick={handleFinishValidation}
                  />
                </>
              )
          )}

          {!fetchResult.error && fetchResult.message === "Hubo un problema con el envio de los archivos" &&
              (
                <>
                  <img src={CancelImg} alt="Cancel" className="w-24 h-24 self-center" />

                  <p className="text-center mt-2 text-lg md:text-xl">Hubo en error con el envío de sus documentos, por favor reintente la validación.</p>

                  <input
                    className="fna-color font-bold py-2 px-4 mt-5 mb-5 rounded focus:outline-none focus:shadow-outline"
                    type="button"
                    value="Reintentar"
                    onClick={refreshPage}
                  />
                </>
              )
          }
          
          {fetchResult.message === "El recurso fue creado" &&
            (
              <>
                <p className="text-lg md:text-xl">
                  Haz finalizado, ya puedes cerrar esta página dando clic en Finalizar.
                </p>
                <img src={CheckMark} alt="Check Mark" className="w-24 h-24 self-center" />
                <input
                  className="fna-color py-2 px-4 mt-5 mb-5 rounded focus:outline-none focus:shadow-outline cursor-pointer"
                  type="button"
                  value="Finalizar"
                  onClick={handleFinishValidation}
                />
              </>
            )}

          {fetchResult.message === "Ya existe un registro con el mismo ID para esta compañia" &&
            (
              <>
                <img src={CancelImg} alt="Cancel" className="w-24 h-24 self-center" />
                <p className="text-center text-lg md:text-xl">Ya existe una validación de identidad para este usuario.</p>
              </>
            )}

          {fetchResult.message === "Intentos excedidos" &&
            (
              <>
                <img src={CancelImg} alt="Cancel" className="w-24 h-24 self-center" />
                <p className="text-center text-lg md:text-xl">Se ha sobre pasado el número de intentos permitidos.</p>
              </>
            )}

          {hadError &&
            (
              <>
                <img src={CancelImg} alt="Cancel" className="w-24 h-24 self-center" />
                <p className="text-center text-lg md:text-xl">Hemos perdido la conexión contigo</p>
                <p className="text-center text-lg md:text-xl">Tenemos dificultades para comunicarnos con nuestros servidores</p>
                <input
                  className="fna-color-secoundary font-bold py-2 px-4 mt-5 mb-5 rounded focus:outline-none focus:shadow-outline"
                  type="button"
                  value="Volver a intentar"
                  onClick={refreshPage}
                />
              </>
            )}
        </div>
        )}
    </Fragment>
  );
};

export default FinalStep;
