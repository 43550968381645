import React, { useState, useEffect } from "react";
import StepperForm from "./StepperForm";
import { getParams } from "../Utils";

const Form = () => {
  const [error, setError] = useState(false);
  const [canVideo, setCanVideo] = useState();
  const [contractId, setContractId] = useState();
  const [userId, setUserId] = useState();
  const [token, setToken] = useState();
  const [country, setCountry] = useState();
  const [stateReq, setState] = useState();
  const [phoneNumberEnc, setPhoneNumberEnc] = useState();

  var getContractById = function (id, token) {
    const URL_CONTRACT =
      "https://becomedigital-api.azurewebsites.net/api/v1/contract/" + id;
    fetch(URL_CONTRACT, {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${token}`,
      }),
    }).then((response) => {
      console.log(response);
      if (response.ok) {
        response.json().then((data) => {
          if (data.id) {
            setCanVideo(data.canSendVideo);
          } else {
            setError(true);
          }
        });
      } else {
        setError(true);
      }
    });
  };

  useEffect(() => {
    let params = getParams(window.location.href);

    if (params.accessToken && params.userId && params.contractId) {
      getContractById(params.contractId, params.accessToken);
      setContractId(params.contractId);
      setUserId(params.userId);
      setToken(params.accessToken);
      if ("phoneNumberEnc" in params) {
        setPhoneNumberEnc(params.phoneNumberEnc);
      }

      if ("country" in params) {
        const countries = [
          "AD",
          "AE",
          "AF",
          "AG",
          "AI",
          "AL",
          "AM",
          "AO",
          "AR",
          "AT",
          "AU",
          "AW",
          "AZ",
          "BA",
          "BB",
          "BD",
          "BE",
          "BF",
          "BG",
          "BH",
          "BI",
          "BJ",
          "BM",
          "BN",
          "BO",
          "BR",
          "BS",
          "BT",
          "BW",
          "BZ",
          "CA",
          "CD",
          "CF",
          "CG",
          "CH",
          "CI",
          "CL",
          "CM",
          "CN",
          "CO",
          "CR",
          "CW",
          "CY",
          "CZ",
          "DE",
          "DJ",
          "DK",
          "DM",
          "DO",
          "DZ",
          "EC",
          "EE",
          "EG",
          "ER",
          "ES",
          "ET",
          "FI",
          "FJ",
          "FR",
          "GA",
          "GB",
          "GD",
          "GE",
          "GH",
          "GM",
          "GN",
          "GQ",
          "GR",
          "GT",
          "GW",
          "GY",
          "HK",
          "HN",
          "HR",
          "HT",
          "HU",
          "ID",
          "IE",
          "IL",
          "IN",
          "IS",
          "IT",
          "JM",
          "JO",
          "JP",
          "KE",
          "KG",
          "KH",
          "KI",
          "KM",
          "KN",
          "KR",
          "KV",
          "KW",
          "KZ",
          "LA",
          "LB",
          "LC",
          "LI",
          "LK",
          "LS",
          "LT",
          "LU",
          "LV",
          "LY",
          "MA",
          "MC",
          "MD",
          "ME",
          "MG",
          "MK",
          "ML",
          "MM",
          "MN",
          "MT",
          "MU",
          "MV",
          "MW",
          "MX",
          "MY",
          "MZ",
          "NA",
          "NE",
          "NG",
          "NI",
          "NL",
          "NO",
          "NP",
          "NR",
          "NU",
          "NZ",
          "OM",
          "PA",
          "PE",
          "PG",
          "PH",
          "PK",
          "PL",
          "PS",
          "PT",
          "PY",
          "QA",
          "RO",
          "RS",
          "RU",
          "RW",
          "SA",
          "SB",
          "SC",
          "SE",
          "SG",
          "SI",
          "SK",
          "SL",
          "SM",
          "SN",
          "SO",
          "SR",
          "SS",
          "ST",
          "SV",
          "SZ",
          "TD",
          "TG",
          "TH",
          "TJ",
          "TL",
          "TM",
          "TN",
          "TO",
          "TP",
          "TR",
          "TT",
          "TV",
          "TZ",
          "UA",
          "UG",
          "US",
          "UY",
          "UZ",
          "VA",
          "VE",
          "VN",
          "VU",
          "WS",
          "YE",
          "ZA",
          "ZM",
        ];
        if (countries.includes(params.country)) {
          setCountry(params.country);

          if ("state" in params) {
            const states = [
              "AL",
              "AK",
              "AZ",
              "AR",
              "CA",
              "CO",
              "CT",
              "DE",
              "DC",
              "FL",
              "GA",
              "HI",
              "ID",
              "IL",
              "IN",
              "IA",
              "KS",
              "KY",
              "LA",
              "ME",
              "MD",
              "MA",
              "MI",
              "MN",
              "MS",
              "MO",
              "MT",
              "NE",
              "NV",
              "NJ",
              "NM",
              "NY",
              "NC",
              "NH",
              "ND",
              "OH",
              "OK",
              "OR",
              "PA",
              "RI",
              "SC",
              "SD",
              "TN",
              "TX",
              "UT",
              "VT",
              "VA",
              "WA",
              "WV",
              "WI",
              "WY",
            ];
            if (states.includes(params.state) && params.country === "US") {
              setState(params.state);
            } else {
              setError(true);
            }
          }
        } else {
          setError(true);
        }
      }
    } else {
      setError(true);
    }
  }, []);
  return (
    <StepperForm
      error={error}
      canVideo={canVideo}
      userId={userId}
      token={token}
      contractId={contractId}
      country={country}
      stateReq={stateReq}
      phoneNumberEnc={phoneNumberEnc}
    />
  );
};

export default Form;
