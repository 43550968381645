import React, { useState } from "react";

export default function DataProcessingPolicy(props) {
    const [showModal, setShowModal] = useState(false);
    const handleAccept = () => {
        setShowModal(false)
    }

    return (
        <>
            <button
                className="text-sm ease-linear transition-all duration-150 text-blue-500"
                type="button"
                onClick={() => setShowModal(true)}
            >
                Política de tratamiento de datos
            </button>
            {showModal && (
                <>
                    <div
                        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div className="relative w-auto my-6 mx-auto max-w-6xl">
                            {/*content*/}
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                {/*header*/}
                                <div className="flex items-start justify-between p-4 border-b border-solid border-slate-200 rounded-t">
                                    <h3 className="text-2xl font-semibold text-slate-800">
                                        Política de tratamiento de datos
                                    </h3>
                                    <button
                                        className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                        onClick={() => setShowModal(false)}
                                    >
                                        <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                            ×
                                        </span>
                                    </button>
                                </div>
                                {/*body*/}
                                <div className="relative p-6 flex-auto text-justify overflow-y-auto">
                                    <p className="my-4 text-slate-600 text-lg leading-relaxed">
                                        BECOME DIGITAL es Encargado del tratamiento de sus datos personales, en tal virtud requiere recolectar, consultar y usar dicha información personal para las siguientes finalidades: (1) Atender requerimientos de autoridades judiciales o administrativas; (2) Adelantar investigaciones en caso de presentarse situaciones de riesgo o violaciones a la seguridad; (3) Gestionar la seguridad en todos sus aspectos; (4) Al realizar actividades de validación de identidad haciendo uso de biometría.
                                    </p>
                                    <p className="my-4 text-slate-600 text-lg leading-relaxed">
                                        En relación con esta autorización, le informamos de forma previa a la captura de sus datos biometricos a través de medios electrónicos, acerca de lo siguiente: i.Que el suministro de su información biometica es facultativo y no obligatorio, ii.Que los datos biometricos se entienden como datos personales sensibles, razón por la cual se requiere de su autorización explícita para el tratamiento de los mismas, de conformidad con lo regulado en los artículos 5 y 6 de la ley 1581 de 2012 y en el artículo 6 del Decreto 1377 de 2013, iii.Que la información biométrica capturada podrá ser almacenada y usada por BECOME DIGITAL para complementar otras bases de datos, pero no fines distintos a los expresados en la presente autorización y en la regulación aplicable.
                                    </p>
                                    <p className="my-4 text-slate-600 text-lg leading-relaxed">
                                        Así mismo, se le informa que como titular de sus datos personales usted tiene derecho a: (i) Acceder en forma gratuita a los datos proporcionados que hayan sido objeto de tratamiento. (ii) Conocer, actualizar y rectificar su información frente a datos parciales, inexactos, incompletos, fraccionados, que induzcan a error, o a aquellos cuyo tratamiento esté prohibido o no haya sido autorizado. (iii) Solicitar prueba de la autorización otorgada. (iv) Presentar ante la Superintendencia de Industria y Comercio (SIC) quejas por infracciones a lo dispuesto en la normatividad vigente. (v) Revocar la autorización y/o solicitar la supresión del dato, siempre que no exista un deber legal o contractual que impida eliminarlos. (vi) Abstenerse de responder las preguntas sobre datos sensibles.
                                    </p>
                                    <p className="my-4 text-slate-600 text-lg leading-relaxed">
                                        El suministro de la información solicitada durante el proceso es de manera voluntaria, por lo tanto, se entenderá como una conducta inequivoca de que otorga su consentimiento de forma libre, voluntaria y expresa a BECOME DIGITAL para que trate la información personal suministrada únicamente para las finalidades descritas en la parte superior de este aviso de privacidad. Como titular de los datos puede ejercer sus derechos de acuerdo con los procedimientos y requisitos descritos en nuestra política, la cual puede consultar escribiéndonos un correo lectrónico a <a href="mailto:soporte@becomedigital.com.co">soporte@becomedigital.com.co</a> o ingresando a nuestra página web <a href="https://www.becomedigital.net" target="_blank" rel="noopener noreferrer nofollow">www.becomedigital.net</a>.
                                    </p>
                                </div>
                                {/*footer*/}
                                <div className="flex items-center justify-end p-4 border-t border-solid border-slate-200 rounded-b">
                                    <button
                                        className="bg-blue-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={handleAccept}
                                    >
                                        Aceptar
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            )}
        </>
    );
}