import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import './styles/stepper.scss';

const Stepper = ({ steps, showNumber, activeStep, onSelect, canVideo }) => {
  const lastIndexOfSteps = steps.length - 1;

  return (
    <Fragment>
      <div className="stepper-container w-full lg:mb-8">
        {steps.map((step, index) => {
          return (
            <Fragment key={index}>
              <div className="stepper-item">
                <div className="stepper-item-outer" onClick={onSelect.bind(null, index + 1)}>
                  <div className={`stepper-item-inner ${activeStep === (index + 1) ? 'stepper-item-inner-active' : (index + 1) < activeStep ? 'stepper-item-inner-completed' : 'stepper-item-inner-future'}`}>  {showNumber && index + 1} </div>
                </div>
                {activeStep === (index+1) ? (<span className={`stepper-title ${activeStep === (index + 1) ? 'stepper-title-active' : ''}`}> {step.title} </span>) : ''  }
              </div>
              {lastIndexOfSteps === index ? '' : <div className="stepper-item-outer"> </div>}
            </Fragment>
          )
        })}
      </div>
    </Fragment>
  )
}

Stepper.defaultProps = {
  steps: [{ title: 'Step 1' }, { title: 'Step 2' }, { title: 'Step 3' }, { title: 'Step 4' }],
  showNumber: false,
  activeStep: 2,
  onSelect: (step) => { }
}

Stepper.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.shape({ title: PropTypes.string })),
  showNumber: PropTypes.bool,
  activeStep: PropTypes.number,
  onSelect: PropTypes.func
}

export default Stepper;