import React from "react";
import WebcamCapture from "../WebcamCapture";
import FrontImg from '../../assets/images/front-document.png';

const FrontDocument = ({ dispatch, handleOnClickNext }) => {
  return (
    <div className="w-full flex flex-col align-center justify-evenly flex-grow fade-in">
      <h2 className="block text-gray-700 text-base font-bold mb-2 mt-4 text-base md:text-lg xl:text-xl lg:mt-8 lg:mb-4">
        Cargue la imagen de la parte frontal
      </h2>
      <img src={FrontImg} alt="FrontDoc" className="object-contain w-1/3 h-1/3 xl:w-1/3 xl:h-1/3 self-center" />
      <WebcamCapture content="Cargar imagen frontal" handleOnClickNext={handleOnClickNext} setFrontImg={true} dispatch={dispatch} />
    </div>
  );
};

export default FrontDocument;
