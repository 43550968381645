import React, { useEffect } from "react";
import { sendMessage } from "../Utils";

const WebcamStreamCapture = ({ dispatch, handleOnClickNext }) => {
  const [errorVideo, setErrorVideo] = React.useState(false);

  useEffect(() => {
    var recorder = document.getElementById("recorder");

    var video = document.createElement("video");

    recorder.addEventListener("change", function (e) {
      var file = e.target.files[0];
      let sizeInMB = (file.size / (1024 * 1024)).toFixed(2);
      video.preload = "metadata";

      video.onloadedmetadata = function () {
        window.URL.revokeObjectURL(video.src);
        if (this.duration > 7 || sizeInMB > 45) {
          setErrorVideo(true);
        } else {
          setErrorVideo(false);
          dispatch({
            type: "selfieVideo",
            value: [file],
          });

          sendMessage('selfieVideo', { "data": 'step completed' })

          handleOnClickNext();
        }
      };

      video.src = URL.createObjectURL(file);
    });
  }, [dispatch, handleOnClickNext]);

  return (
    <>
      {errorVideo && (
        <div class="bg-red-400 text-white font-small py-2">
          <p>Por favor, grabe un video con las siguientes caracteristicas:</p>
          <ul>
            <li>Duración: Igual o menor a 7s</li>
            <li>Tamaño: Maximo 45MB</li>
          </ul>
        </div>
      )}
      
      <div className="relative w-64 mt-4 mb-4 self-center">
        <label className="fna-color font-bold py-2 px-4 w-full inline-flex items-center rounded text-center">
          <span className="text-base leading-normal text-center flex-grow">
            Video haciendo dos circulos con tu cabeza
          </span>
          <input
            id="recorder"
            type="file"
            accept="video/*"
            capture="user"
            className="cursor-pointer hidden"
          ></input>
        </label>
      </div>
    </>
  );
};

export default WebcamStreamCapture;
