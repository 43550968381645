import React, { useEffect } from "react";
import { sendMessage } from "../Utils";

const WebcamCapture = ({ setFrontImg, dispatch, handleOnClickNext, content  }) => {
  useEffect(() => {
    var camera = document.getElementById("camera");

    camera.addEventListener("change", function (e) {
      var file = e.target.files[0];
      // Do something with the image file.
      if (setFrontImg) {
        dispatch({
          type: "frontDocument",
          value: [file],
        });
        sendMessage('frontImage', { "data": 'step completed' })
      } else {
        dispatch({
          type: "backDocument",
          value: [file],
        });
        sendMessage('backImage', { "data": 'step completed' })
      }
      handleOnClickNext();
    });
  }, [dispatch, handleOnClickNext, setFrontImg]);

  return (
    <div className="relative w-64 mt-4 mb-4 self-center">
      <label className="fna-color py-2 px-4 w-full inline-flex items-center rounded text-center">
        <span className="text-base leading-normal text-center flex-grow">
          {content ? content : "Toma una foto."}
        </span>
        <input
          type="file"
          accept="image/*"
          capture="camera"
          id="camera"
          className="cursor-pointer hidden"
        ></input>
      </label>
    </div>
  );
};

export default WebcamCapture;
